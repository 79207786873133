// User Pages

.app-logo {
  height: $logo-height;
  width: $logo-width;
  background: url('../../assets/utils/images/logo-inverse.png');
}

.app-logo-inverse {
  height: $logo-height;
  width: $logo-width;
  background: url('../../assets/utils/images/logo.png');
}

.app-login-box {
  .app-logo {
    margin-bottom: $layout-spacer-lg;
  }

  h4 {
    margin-bottom: $layout-spacer-x;
    font-weight: normal;

    div {
      opacity: .6;
    }

    span {
      font-size: $font-size-lg;
    }
  }
}





/**/



div.card .card-header-primary {
  background: linear-gradient(60deg,#2FA4E7,#2FA4E7);
  box-shadow: 0 5px 20px 0 rgba(0,0,0,.2), 0 13px 24px -11px rgba(156,39,176,.6);
}

div.card .card-header-danger {
  background: linear-gradient(60deg,#2FA4E7,#2FA4E7);
  box-shadow: 0 5px 20px 0 rgba(0,0,0,.2), 0 13px 24px -11px rgba(244,67,54,.6);
}


.card-nav-tabs .card-header {
  margin-top: -30px!important;
}

.card .card-header .nav-tabs {
  padding: 0;
}


.nav-tabs {
  border: 0;
  border-radius: 3px;
  padding: 0 15px;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-item .nav-link.active {
  background-color: hsla(0,0%,100%,.2);
  transition: background-color .3s .2s;
}

.nav-tabs .nav-item .nav-link{
  border: 0!important;
  color: #fff!important;
  font-weight: 500;
}

.nav-tabs .nav-item .nav-link {
  color: #fff;
  border: 0;
  margin: 0;
  border-radius: 3px;
  line-height: 24px;
  text-transform: uppercase;
  font-size: 12px;
  padding: 10px 15px;
  background-color: transparent;
  transition: background-color .3s 0s;
}

.nav-link{
  display: block;
}

.nav-tabs .nav-item .material-icons {
  margin: -1px 5px 0 0;
  vertical-align: middle;
}

.nav .nav-item {
  position: relative;
}