// Content Boxes

// Variables

@use "sass:math";

$widget-spacer: $spacer;

.widget-content {
  padding: $widget-spacer;
  flex-direction: row;
  align-items: center;

  .widget-content-wrapper {
    display: flex;
    flex: 1;
    position: relative;
    align-items: center;
  }

  .widget-content-left {

    .widget-heading {
      opacity: 0.9;
      font-weight: bold;
    }

    .widget-subheading {
      opacity: 0.9;
    }
  }

  .widget-content-right {
    margin-left: auto;
  }

  .widget-numbers {
    font-weight: bold;
    font-size: 1.8rem;
    display: block;
  }

  .widget-content-outer {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  .widget-progress-wrapper {
    margin-top: $widget-spacer;

    .progress-sub-label {
      margin-top: math.div($widget-spacer, 3);
      opacity: .5;
      display: flex;
      align-content: center;
      align-items: center;

      .sub-label-left {

      }

      .sub-label-right {
        margin-left: auto;
      }
    }
  }

  .widget-content-right {
    &.widget-content-actions {
      visibility: hidden;
      opacity: 0;
      transition: opacity .2s;
    }
  }

  &:hover {
    
    .widget-content-right {
      &.widget-content-actions {
        visibility: visible;
        opacity: 1;
      }
    }

    .widget-heading {
      opacity: 1;
      font-weight: bold;
    }

    .widget-subheading {
      opacity: 1;
    }

  }
}

.ListGroup-Item {

  &:hover {
    background-color: $primary;
    color:white;
    cursor: pointer;

    .widget-content-right {
      &.widget-content-actions {
        visibility: visible;
        //opacity: 1;
      }
    }
  }
}

.Card-Header {
color: $primary;
}

.theme-shadow {
  box-shadow: var(--box-shadow);
}

/* ICONBOX */
.iconbox {
  width: 60px;
  height: 60px;
  display: inline-flex;
  //align-items: center;
  //justify-content: center;
  font-size: 44px;
  flex: none;
}

/* SERVICE */
.service {
  position: relative;
  overflow: hidden;
  z-index: 2;
  background-color: white;
  color: $primary;
  //width: 100%;
  align-items: center;
  justify-content: center;

  p {
    color: black;
    opacity: 0.8;
  }
  h5 {
    color: $primary;
    font-size: 22px;
  }

  &:after {
    content: "";
    width: 40px;
    height: 40px;
    background: $primary;
    position: absolute;
    bottom: 0;
    right: 0;
    transition: var(--transition);
  }

  &:hover {
    cursor: pointer;

    .iconbox {
      color: white;
    }

    p {
      color: white;
      opacity: 1;
    }
    h5 {
      color: white;
    }

    &:after {
      width: 100%;
      height: 100%;
      background-color: $primary;
      z-index: -1;
    }

  }
}








