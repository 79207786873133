// tooltip

.sectionT {
    display: flex;
    //justify-content: center;
    //align-items: center;
    position: relative;
    //width: 100%;
    //height: 100vh;
   }

   .sectionProfile {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    //width: 100%;
    //height: 100vh;
   }

   /*h3 {
    font-size: 48px;
    font-weight: 600;
    color: #fff;
    padding-left: 10px;
   }*/

   .tooltipH {
    position: relative;
    background-color: #0092D1;
    padding: 1px 6px;
    margin: 5px;
    font-size: 12px;
    border-radius: 100%;
    color: white;
    width: 100%;
   
    &:before,
    &:after {
     position: absolute;
     content: '';
     opacity: 0;
     transition: all 0.4s ease;
    }
 
    &:before {
        border-width: 10px 8px 0 8px;
        border-style: solid;
        border-color: #0092D1 transparent transparent transparent;
        top: -10px;
        transform: translateY(20px);
       }

       &:after {
        content: attr(data-tooltip);
        background: #0092D1;
        //width: 160px;
        //height: 50px;
        font-size: 14px;
        font-weight: 400;
        top: -51px;
        left: -10px;
        padding: 10px;
        border-radius: 5px;
        letter-spacing: 1px;
        transform: translateY(20px);
       }

       &:hover::before,
       &:hover::after {
        opacity: 1;
        transform: translateY(-2px);
       }

}

   @keyframes shake {
    0% { 
    transform: rotate(2deg);
    }
    50% {
    transform: rotate(-3deg);
    }
    70% {
    transform: rotate(3deg);
    }
    100% {
    transform: rotate(0deg);
    }
   }

   #anim {
    &:hover{
        animation: shake 500ms ease-in-out forwards;
    }
    
   }


   #cart_menu_num {
    position: absolute;
    top: 0;
    left: 55%;
    background: red;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    padding: 2px;
  }