// List Group

@use "sass:math";

.todo-list-wrapper {
  .todo-indicator {
    position: absolute;
    width: 4px;
    height: 60%;
    @include border-radius($border-radius-lg);
    left: math.div($card-spacer-x, 2);
    top: 20%;
    opacity: 1;
    transition: opacity .2s;
    background-color:$primary;
  }

  .list-group-item {
    background-color: white;

    &:hover {
      background-color: white;
      border-color: $primary;
      color: black;

      .todo-indicator {
        opacity: 1;
        background-color: $primary;
      }
    }


  }

  .selectI {
    background-color: $primary;
    border-color: white;
    color: white;

    .todo-indicator {
      opacity: 1;
      background-color: white;
    }
  }

  .custom-control,
  input[checkbox] {
    margin-left: math.div($card-spacer-x, 2);
  }
}

.list-group-flush + .card-footer {
  border-top: 0;
}

.rm-list-borders {
  .list-group-item {
    border: 0;
    padding: math.div($card-spacer-y, 1.5) 0;
  }
}

.rm-list-borders-scroll {
  .list-group-item {
    border: 0;
    padding-right: ($card-spacer-y * 1.5);
  }
}

.list-answer{
  float: right;
  padding-right: 5px;
}

.list-answer-content{
  float: left;
  padding-right: 5px;
  padding-top: 10px;
  padding-bottom: 5px;
}

.list-inspection-content{
  width: 100%;
  float: left;
  padding-right: 5px;
  padding-top: 10px;
  padding-bottom: 5px;
}